import { userPreferences } from '#packages/stateManagement';
import constants from '#packages/constants';
import experiment from 'experiment';
import type { EditorAPI } from '#packages/editorAPI';

import type { ColorName, Preset } from './types';
import { getColorNameByRole } from './utils';

const isNewRegexOpen = experiment.isOpen('se_colorPaletteUseNewRegEx');

const digitsRegex = /\d+/;
const PALETTE_BOTTOM_NUMBER_RANGE = 11;
const PALETTE_UPPER_NUMBER_RANGE = 35;
const PALETTE_UPPER_NUMBER_RANGE_EXTENDED = 65;
const THEME_COLOR_REGEX = /color_[0-9]?[0-9](?![a-zA-Z0-9-=/\\,.()])/g;
const TEXT_COLOR_CLASS_REGEX = isNewRegexOpen
  ? /class="[^"]*\bcolor_\d{1,2}\b[^"]*"/g
  : /class="color_\d{1,2}"/g;

const TEXT_COLOR_BACKGROUND_COLOR_REGEX =
  /class=(?:"|')?(backcolor_|color_)(\d+)(?:"|')?/;

const COLOR_PREFIX = 'color_';
const EMPTY_COLOR_NAME_VALUE = '__empty__';
const VISIBLE_THEMES_COLOR_LIMIT = 10;
const COLOR_REGEX = /color_\d{1,2}/g;
const COLOR_NAME_OR_HEX_REGEXP = /color_\d*|#[0-9A-Fa-f]{6}/g;
const HEX_REGEX = /#[0-9A-Fa-f]{3,6}/g;
const TEXT_HEX_STYLE_REGEX = /style="color:#[0-9A-Fa-f]{3,6};"/g;
const ACCENT_COLOR_REGEX = /color_(41|42|43|44)/;

const isNewColorPaletteOpen = (editorAPI: EditorAPI) => {
  return !!userPreferences.selectors.getSiteUserPreferences(
    constants.USER_PREFS.THEME.COLORS.IS_MIGRATED,
  )(editorAPI.store.getState());
};

const areColorsSynchronised = (editorAPI: EditorAPI) => {
  return !!userPreferences.selectors.getSiteUserPreferences(
    constants.USER_PREFS.THEME.COLORS.ARE_COLORS_SYNCHRONISED,
  )(editorAPI.store.getState());
};

const generatePaletteColors = (start: number, limit: number) => {
  const palette: ColorName[] = [];
  for (let i = start; i <= limit; i += 1) {
    palette.push(`color_${i}` as ColorName);
  }
  return palette;
};

const OLD_PALETTE_DEPRECATED_COLORS = generatePaletteColors(
  0,
  PALETTE_BOTTOM_NUMBER_RANGE - 1,
);

const OLD_PALETTE_COLORS = generatePaletteColors(
  PALETTE_BOTTOM_NUMBER_RANGE,
  PALETTE_UPPER_NUMBER_RANGE,
);

const MAIN_THEME_COLORS = generatePaletteColors(36, 41);

const THEME_COLORS = [...MAIN_THEME_COLORS, ...generatePaletteColors(42, 44)];

const ADVANCED_COLORS: ColorName[] = [
  'color_11',
  'color_12',
  'color_14',
  'color_15',
  'color_18',
  ...generatePaletteColors(45, 65),
];

const NEW_PALETTE_COLORS: ColorName[] = [...THEME_COLORS, ...ADVANCED_COLORS];

const PALETTE_COLORS: ColorName[] = [
  ...new Set([...OLD_PALETTE_COLORS, ...NEW_PALETTE_COLORS]),
];

const PALETTE_SEQUENCES = {
  color_13: PALETTE_COLORS.slice(0, 5),
  color_18: PALETTE_COLORS.slice(5, 10),
  color_23: PALETTE_COLORS.slice(10, 15),
  color_28: PALETTE_COLORS.slice(15, 20),
  color_33: PALETTE_COLORS.slice(20, 25),
};

const PALETTE_SEQUENCES_MATRIX = Object.values(PALETTE_SEQUENCES);

const PALETTE_SEQUENCES_MATRIX_INDEXES = PALETTE_SEQUENCES_MATRIX.map(
  (group, groupIdx) => group.map((_, colorIdx) => [groupIdx, colorIdx]),
).flat();

export enum COLOR_ROLES {
  MAIN_1 = 'MAIN_1',
  MAIN_2 = 'MAIN_2',
  SHADE_1 = 'SHADE_1',
  SHADE_2 = 'SHADE_2',
  SHADE_3 = 'SHADE_3',
  SECONDARY_1 = 'SECONDARY_1',
  SECONDARY_2 = 'SECONDARY_2',
  SECONDARY_3 = 'SECONDARY_3',
  SECONDARY_4 = 'SECONDARY_4',
  BACKGROUND = 'BACKGROUND',
  SECONDARY_BACKGROUND = 'SECONDARY_BACKGROUND',
  SECONDARY_TEXT = 'SECONDARY_TEXT',
  PRIMARY_TEXT = 'PRIMARY_TEXT',
  LINK = 'LINK',
  TITLE = 'TITLE',
  SUBTITLE = 'SUBTITLE',
  LINE = 'LINE',
  PRIMARY_BUTTON_FILL = 'PRIMARY_BUTTON_FILL',
  PRIMARY_BUTTON_BORDER = 'PRIMARY_BUTTON_BORDER',
  PRIMARY_BUTTON_TEXT = 'PRIMARY_BUTTON_TEXT',
  PRIMARY_BUTTON_FILL_HOVER = 'PRIMARY_BUTTON_FILL_HOVER',
  PRIMARY_BUTTON_BORDER_HOVER = 'PRIMARY_BUTTON_BORDER_HOVER',
  PRIMARY_BUTTON_TEXT_HOVER = 'PRIMARY_BUTTON_TEXT_HOVER',
  PRIMARY_BUTTON_FILL_DISABLED = 'PRIMARY_BUTTON_FILL_DISABLED',
  PRIMARY_BUTTON_BORDER_DISABLED = 'PRIMARY_BUTTON_BORDER_DISABLED',
  PRIMARY_BUTTON_TEXT_DISABLED = 'PRIMARY_BUTTON_TEXT_DISABLED',
  SECONDARY_BUTTON_FILL = 'SECONDARY_BUTTON_FILL',
  SECONDARY_BUTTON_BORDER = 'SECONDARY_BUTTON_BORDER',
  SECONDARY_BUTTON_TEXT = 'SECONDARY_BUTTON_TEXT',
  SECONDARY_BUTTON_FILL_HOVER = 'SECONDARY_BUTTON_FILL_HOVER',
  SECONDARY_BUTTON_BORDER_HOVER = 'SECONDARY_BUTTON_BORDER_HOVER',
  SECONDARY_BUTTON_TEXT_HOVER = 'SECONDARY_BUTTON_TEXT_HOVER',
  SECONDARY_BUTTON_FILL_DISABLED = 'SECONDARY_BUTTON_FILL_DISABLED',
  SECONDARY_BUTTON_BORDER_DISABLED = 'SECONDARY_BUTTON_BORDER_DISABLED',
  SECONDARY_BUTTON_TEXT_DISABLED = 'SECONDARY_BUTTON_TEXT_DISABLED',
}

const COLOR_ROLE_TO_COLOR_NAME_MAP: Record<COLOR_ROLES, ColorName> = {
  [COLOR_ROLES.BACKGROUND]: 'color_11',
  [COLOR_ROLES.SECONDARY_BACKGROUND]: 'color_12',
  [COLOR_ROLES.SECONDARY_TEXT]: 'color_14',
  [COLOR_ROLES.PRIMARY_TEXT]: 'color_15',
  [COLOR_ROLES.LINK]: 'color_18',
  [COLOR_ROLES.MAIN_1]: 'color_36',
  [COLOR_ROLES.MAIN_2]: 'color_37',
  [COLOR_ROLES.SHADE_1]: 'color_38',
  [COLOR_ROLES.SHADE_2]: 'color_39',
  [COLOR_ROLES.SHADE_3]: 'color_40',
  [COLOR_ROLES.SECONDARY_1]: 'color_41',
  [COLOR_ROLES.SECONDARY_2]: 'color_42',
  [COLOR_ROLES.SECONDARY_3]: 'color_43',
  [COLOR_ROLES.SECONDARY_4]: 'color_44',
  [COLOR_ROLES.TITLE]: 'color_45',
  [COLOR_ROLES.SUBTITLE]: 'color_46',
  [COLOR_ROLES.LINE]: 'color_47',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL]: 'color_48',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER]: 'color_49',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT]: 'color_50',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER]: 'color_51',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER_HOVER]: 'color_52',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT_HOVER]: 'color_53',
  [COLOR_ROLES.PRIMARY_BUTTON_FILL_DISABLED]: 'color_54',
  [COLOR_ROLES.PRIMARY_BUTTON_BORDER_DISABLED]: 'color_55',
  [COLOR_ROLES.PRIMARY_BUTTON_TEXT_DISABLED]: 'color_56',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL]: 'color_57',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER]: 'color_58',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT]: 'color_59',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL_HOVER]: 'color_60',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER_HOVER]: 'color_61',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER]: 'color_62',
  [COLOR_ROLES.SECONDARY_BUTTON_FILL_DISABLED]: 'color_63',
  [COLOR_ROLES.SECONDARY_BUTTON_BORDER_DISABLED]: 'color_64',
  [COLOR_ROLES.SECONDARY_BUTTON_TEXT_DISABLED]: 'color_65',
};

const ADVANCED_COLORS_MAPPING = {
  [getColorNameByRole(COLOR_ROLES.BACKGROUND)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BACKGROUND)]: 'color_38',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_TEXT)]: 'color_40',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT)]: 'color_37',
  [getColorNameByRole(COLOR_ROLES.LINK)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.TITLE)]: 'color_37',
  [getColorNameByRole(COLOR_ROLES.SUBTITLE)]: 'color_37',
  [getColorNameByRole(COLOR_ROLES.LINE)]: 'color_40',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_FILL)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_BORDER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_TEXT)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_BORDER_HOVER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_TEXT_HOVER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_FILL_DISABLED)]: 'color_39',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_BORDER_DISABLED)]: 'color_39',
  [getColorNameByRole(COLOR_ROLES.PRIMARY_BUTTON_TEXT_DISABLED)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_FILL)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_BORDER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_TEXT)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_FILL_HOVER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_BORDER_HOVER)]: 'color_41',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_FILL_DISABLED)]: 'color_36',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_BORDER_DISABLED)]:
    'color_39',
  [getColorNameByRole(COLOR_ROLES.SECONDARY_BUTTON_TEXT_DISABLED)]: 'color_39',
};

const createColorNamesToRolesMap = (): Record<ColorName, COLOR_ROLES> => {
  const COLOR_NAME_TO_ROLE: Record<ColorName, COLOR_ROLES> = {} as Record<
    ColorName,
    COLOR_ROLES
  >;

  for (const key in COLOR_ROLE_TO_COLOR_NAME_MAP) {
    if (COLOR_ROLE_TO_COLOR_NAME_MAP.hasOwnProperty(key)) {
      COLOR_NAME_TO_ROLE[
        COLOR_ROLE_TO_COLOR_NAME_MAP[key as COLOR_ROLES] as ColorName
      ] = key as COLOR_ROLES;
    }
  }
  return COLOR_NAME_TO_ROLE;
};

const COLOR_NAMES_TO_ROLES_MAP = createColorNamesToRolesMap();

const ALL_ACCENTS_COLORS = [
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_1,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_2,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_3,
  COLOR_ROLE_TO_COLOR_NAME_MAP.SECONDARY_4,
];

const PRESETS: Preset[] = [
  {
    getApi: (editorAPI) => editorAPI.components.design,
    colorPaths: [['overrideColors'], ['shapeStyle'], ['background']],
  },
  // component type shape
  {
    getApi: (editorAPI) => editorAPI.components.design,
    colorPaths: [['overrideColors'], ['color1']],
  },
  {
    getApi: (editorAPI) => editorAPI.components.style,
    colorPaths: [['style', 'properties']],
  },
  // component type text
  {
    getApi: (editorAPI) => editorAPI.components.data,
    colorPaths: [['text']],
  },
];

export {
  digitsRegex,
  isNewColorPaletteOpen,
  areColorsSynchronised,
  PALETTE_BOTTOM_NUMBER_RANGE,
  PALETTE_UPPER_NUMBER_RANGE,
  PALETTE_UPPER_NUMBER_RANGE_EXTENDED,
  THEME_COLOR_REGEX,
  COLOR_REGEX,
  TEXT_COLOR_CLASS_REGEX,
  PALETTE_SEQUENCES,
  PALETTE_SEQUENCES_MATRIX,
  PALETTE_SEQUENCES_MATRIX_INDEXES,
  PALETTE_COLORS,
  NEW_PALETTE_COLORS,
  OLD_PALETTE_COLORS,
  OLD_PALETTE_DEPRECATED_COLORS,
  COLOR_PREFIX,
  COLOR_ROLE_TO_COLOR_NAME_MAP,
  COLOR_NAMES_TO_ROLES_MAP,
  EMPTY_COLOR_NAME_VALUE,
  VISIBLE_THEMES_COLOR_LIMIT,
  THEME_COLORS,
  MAIN_THEME_COLORS,
  ADVANCED_COLORS,
  ADVANCED_COLORS_MAPPING,
  ALL_ACCENTS_COLORS,
  PRESETS,
  HEX_REGEX,
  TEXT_HEX_STYLE_REGEX,
  ACCENT_COLOR_REGEX,
  TEXT_COLOR_BACKGROUND_COLOR_REGEX,
  COLOR_NAME_OR_HEX_REGEXP,
};
