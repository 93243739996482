import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey, EditorCoreApiKey } from '#packages/apis';

import { ThemeApiKey } from './publicApiKey';
import { createThemeApi } from './themeWrapper';
import { runThemeMigrations } from './migrations';
import experiment from 'experiment';

import type { EditorAPI } from '#packages/editorAPI';

const setThemeIfPossible = async (editorAPI: EditorAPI) => {
  // TODO export to package if poc is successful
  // eslint-disable-next-line @wix/santa-editor/scoped-imports
  const { setThemeFromParam } = await import(
    '@/newDesignPanel/Theme/themeUtils'
  );
  setThemeFromParam(editorAPI);
};

export class ThemeScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
}

export const ThemeEntrypoint = createEntryPoint({
  name: 'Theme',
  Scope: ThemeScope,
  publicApi({ contributeApi }) {
    contributeApi(ThemeApiKey, createThemeApi);
  },
  async initialize({ editorAPI, editorCoreAPI }: ThemeScope) {
    await editorCoreAPI.hooks.initReady.promise;
    await editorCoreAPI.hooks.userPreferencesLoaded.promise;

    await runThemeMigrations(editorAPI);

    if (
      experiment.isOpen('se_updateThemeFromParam') &&
      editorAPI.dsRead.generalInfo.isDraft()
    ) {
      setThemeIfPossible(editorAPI);
    }
  },
});
