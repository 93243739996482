// @ts-nocheck
import { colors } from '#packages/util';
import {
  digitsRegex,
  PALETTE_BOTTOM_NUMBER_RANGE,
  PALETTE_UPPER_NUMBER_RANGE,
  PALETTE_UPPER_NUMBER_RANGE_EXTENDED,
  THEME_COLOR_REGEX,
  PALETTE_SEQUENCES,
  COLOR_PREFIX,
} from '../colors/constants';
import type { ColorPalette } from '../colors/types';

function isThemeColorOutOfPaletteRange(
  themeColor: string,
  isNewColorPaletteOpen: boolean = false,
): boolean {
  const colorThemeNumber = Number(themeColor.match(digitsRegex)[0]);
  const upperRange = isNewColorPaletteOpen
    ? PALETTE_UPPER_NUMBER_RANGE_EXTENDED
    : PALETTE_UPPER_NUMBER_RANGE;
  return (
    colorThemeNumber > upperRange ||
    colorThemeNumber < PALETTE_BOTTOM_NUMBER_RANGE
  );
}

const isThemeColor = (color: string) => {
  // New instance is required since the regex has global flag set,
  // test() will advance the lastIndex of the regex, and a subsequent
  // use of test() will start the search at the substring of str specified
  // by lastIndex, thus failing the check.
  const regExp = new RegExp(THEME_COLOR_REGEX);
  return regExp.test(color);
};

const fixThemeColorKey = (color: string) =>
  color.startsWith(COLOR_PREFIX) ? `{${color}}` : color;

const fromThemeColorToHex = (color: string, themeColors: ColorPalette) => {
  if (!isThemeColor(color) || !themeColors || themeColors.length) return color;
  color = getThemeColorKey(color);
  color = themeColors[color];
  return colors.getColorInHex(color);
};

const getThemeColorKey = (color: string) =>
  color.startsWith(`{${COLOR_PREFIX}`) ? color.slice(1, -1) : color;

function getInvertedPalette(palette: ColorPalette): ColorPalette {
  const invertedPalette = {};
  Object.values(PALETTE_SEQUENCES).forEach((sequence) => {
    for (let i = 0; i < sequence.length; i++) {
      invertedPalette[sequence[i]] = palette[sequence[sequence.length - i - 1]];
    }
  });
  return invertedPalette;
}

export {
  // @TODO this is already present in API, remove either one
  isThemeColor,
  getInvertedPalette,
  isThemeColorOutOfPaletteRange,
  fixThemeColorKey,
  fromThemeColorToHex,
  getThemeColorKey,
};
