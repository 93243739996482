import { fedopsLogger } from '#packages/util';

export const scFlowFedops = {
  palleteMigration: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW
          .PALETTE_MIGRATION,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW
          .PALETTE_MIGRATION,
      ),
  },
  setColors: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW
          .COLORS_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW
          .COLORS_SET,
      ),
  },
  setFlag: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW.FLAG_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SC_FLOW.FLAG_SET,
      ),
  },
};

export const exisitingSitesFlowFedops = {
  palleteMigration: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.PALETTE_MIGRATION,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.PALETTE_MIGRATION,
      ),
  },
  setAccents: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.ACCENTS_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.ACCENTS_SET,
      ),
  },
  setFlag: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.FLAG_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.FLAG_SET,
      ),
  },
  removeWiring: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.WIRING_REMOVED,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION
          .EXISTING_SITES_FLOW.WIRING_REMOVED,
      ),
  },
};

export const templatesFedops = {
  palleteMigration: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .PALETTE_MIGRATION,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .PALETTE_MIGRATION,
      ),
  },
  setFlag: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .FLAG_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .FLAG_SET,
      ),
  },
  setAccents: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .ACCENTS_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .ACCENTS_SET,
      ),
  },
  removeWiring: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .WIRING_REMOVED,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.TEMPLATES_FLOW
          .WIRING_REMOVED,
      ),
  },
};

export const generalFlowFedops = {
  setFlag: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.GENERAL_FLOW
          .FLAG_SET,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.GENERAL_FLOW
          .FLAG_SET,
      ),
  },
};

export const syncColorsFlow = {
  paletteMigration: {
    start: () =>
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SYNC_FLOW,
      ),
    end: () =>
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.NEW_COLOR_PALETTE_MIGRATION.SYNC_FLOW,
      ),
  },
};
