import type { EditorAPI } from '#packages/editorAPI';
import { PRESETS } from '../colors/constants';
import { scanPathsAndGetColors } from './utils';
import _ from 'lodash';
import { isThemeColor } from '../colors/utils';

export const getSnapshotOfComponentsAndColors = (
  editorAPI: EditorAPI,
  pageId?: string,
): Map<string, string[]> => {
  const allComponents = editorAPI.components.getAllComponents(pageId);
  const palette = editorAPI.theme.colors.getAll();

  const componentIdsToColorActualColors = new Map<string, string[]>();

  for (const compRef of allComponents) {
    for (const compFunc of PRESETS) {
      const compApi = compFunc.getApi(editorAPI);
      const compData = compApi.get(compRef);

      if (compData) {
        const includeHex = true;
        const colorNames = scanPathsAndGetColors(
          compData,
          compFunc.colorPaths,
          [],
          includeHex,
        );
        if (colorNames.length === 0) {
          continue;
        }

        const colors = colorNames
          .map((maybeColorName: string) => {
            if (isThemeColor(maybeColorName)) {
              return palette[maybeColorName];
            }
            return maybeColorName;
          })
          .sort();

        componentIdsToColorActualColors.set(compRef.id, colors);
      }
    }
  }

  return componentIdsToColorActualColors;
};

export const compareSnapshots = (
  before: Map<string, string[]>,
  after: Map<string, string[]>,
) => {
  let equal = true;
  for (const [componentId, colors] of after) {
    const colorsBefore = before.get(componentId);
    if (colorsBefore && !_.isEqual(colors, colorsBefore)) {
      equal = false;
      break;
    }
  }

  return equal;
};

export const resultsValidator = (
  editorAPI: EditorAPI,
  pageId?: string,
): (() => undefined | MaybeError) => {
  const currentPageId = pageId || editorAPI.pages.getPrimaryPageId();
  const before = getSnapshotOfComponentsAndColors(editorAPI, currentPageId);

  return () => {
    const after = getSnapshotOfComponentsAndColors(editorAPI, currentPageId);
    const isValid = compareSnapshots(before, after);
    if (!isValid) {
      throw Error(`wrong colors after migration`);
    }
  };
};
