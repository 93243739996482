import _ from 'lodash';
import experiment from 'experiment';
import {
  syncNewColorPaletteStart,
  syncNewColorPaletteFinish,
} from '@wix/bi-logger-editor/v2';
import { EditorParamsApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';

import { runTemplatesMigration } from './templatesMigration';

import {
  getIsHeavySite,
  hasNewColorPaletteColorsInComponents,
  saveSiteMigratedFlag,
} from './utils';
import {
  isNewColorPaletteOpen,
  areColorsSynchronised,
} from '../colors/constants';
import { generalFlowFedops as fedops } from './fedops';
import { runSyncColorsMigration } from './syncColorsMigration';
import {
  runExistingSitesMigrationForNeverMigratedSites,
  runExistingSiteMigrationForSiteWithNewColorsInComponents,
  runExistingHeavySitesMigration,
} from './existingSitesMigration';
import { skipMigrationBecauseOfVersion } from './revertMigrations';

import { biLogger } from '#packages/util';

export * from './advancedWiring';
export * from './scFlowMigration';

const newColorPaletteExperimentsOpened = () => {
  return (
    experiment.isOpen('se_colorPaletteRedesignOldSites') ||
    experiment.isOpen('se_colorPaletteReDesign')
  );
};

const setSiteMigratedFlag = (editorAPI: EditorAPI) => {
  fedops.setFlag.start();
  saveSiteMigratedFlag(editorAPI);
  fedops.setFlag.end();
};

const runSyncColorsIfNeed = async (editorAPI: EditorAPI) => {
  if (!newColorPaletteExperimentsOpened()) {
    if (areColorsSynchronised(editorAPI)) {
      biLogger.report(syncNewColorPaletteStart({ action: 'skip' }));
      biLogger.report(syncNewColorPaletteFinish({ action: 'skip' }));
      return;
    }
    await runSyncColorsMigration(editorAPI);
    return;
  }
};

const isSuccesfullyMigrated = (
  editorAPI: EditorAPI,
  hasLinkedColors: boolean,
) => {
  return isNewColorPaletteOpen(editorAPI) && hasLinkedColors;
};

export const runThemeMigrations = async (editorAPI: EditorAPI) => {
  const { siteCreationWizard, isInsideEditorX, isInsideAppStudio } =
    editorAPI.host.getAPI(EditorParamsApiKey);

  // This function runs earlier than SC finishes, so
  // migration for SC flow is triggered from ./packages/rEditor/rootComps/siteCreation/siteCreationPagesViewMapper.ts
  if (isInsideEditorX || isInsideAppStudio || siteCreationWizard) {
    return;
  }

  const hasLinkedColors = !_.isEmpty(
    editorAPI.theme.colors.getAllLinkedColors(),
  );

  if (experiment.isOpen('se_autoWireCompsColors')) {
    if (!isSuccesfullyMigrated(editorAPI, hasLinkedColors)) {
      await runTemplatesMigration(editorAPI);
    }
    return;
  }

  if (isSuccesfullyMigrated(editorAPI, hasLinkedColors)) {
    return;
  }

  await runSyncColorsIfNeed(editorAPI);

  const isExistingSite = !editorAPI.generalInfo.isDraft();

  // For cases when user creates a site from an already migrated template
  if (hasLinkedColors) {
    setSiteMigratedFlag(editorAPI);
    return;
  }

  if (isExistingSite) {
    const isShouldRunOldSiteMigration = experiment.isOpen(
      'se_colorPaletteRedesignOldSites',
    );

    const isHeavySite = getIsHeavySite();

    if (skipMigrationBecauseOfVersion(editorAPI)) {
      return;
    }

    if (isShouldRunOldSiteMigration) {
      if (isHeavySite) {
        await runExistingHeavySitesMigration(editorAPI);
        return;
      }

      const isThereNewColorPaletteColorsInComponents =
        hasNewColorPaletteColorsInComponents(editorAPI);
      // This enables new UI for sites that have migrated but don't have flag set in a case if previously
      // linked colors were added for backward compatibility with new CP widgets
      if (isThereNewColorPaletteColorsInComponents) {
        await runExistingSiteMigrationForSiteWithNewColorsInComponents(
          editorAPI,
        );
        return;
      }
      await runExistingSitesMigrationForNeverMigratedSites(editorAPI);

      return;
    }

    return;
  }

  if (experiment.isOpen('se_colorPaletteReDesign')) {
    await runTemplatesMigration(editorAPI);
  }
};
