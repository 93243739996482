import type { EditorAPI } from '#packages/editorAPI';
import type { ColorPalette as DMColorPalette } from '@wix/document-services-types';
import type { EMPTY_COLOR_NAME_VALUE } from './constants';

export interface RGBColorObject {
  red: number;
  green: number;
  blue: number;
}
export interface HSBColorObject {
  hue: number;
  saturation: number;
  brightness: number;
}
export interface HSLColorObject {
  hue: number;
  saturation: number;
  lightness: number;
}

export interface Preset {
  getApi: (editorApi: EditorAPI) => any;
  colorPaths: string[][];
}

export interface MatchToHexMapper {
  regex: RegExp;
  getHex: (match: string) => string;
  textFlowExtractor: (match: string) => string;
}

export type ColorName = keyof ColorPalette;
export type ColorPalette = Partial<DMColorPalette>;
export type Color = RGBColorObject | HSBColorObject | HSLColorObject | string;
export type LinkedColorValue = ColorName | typeof EMPTY_COLOR_NAME_VALUE;
export type LinkedColors = Partial<Record<ColorName, LinkedColorValue>>;

export class ColorMatch {
  constructor(
    public colorIndex: string,
    public property: 'backcolor' | 'color',
  ) {}
  get colorName() {
    return `color_${this.colorIndex}`;
  }

  get propertyToUpdateName() {
    return `${this.property}_${this.colorIndex}`;
  }

  public setNewColorIndexFromColorName(colorName: ColorName) {
    this.colorIndex = colorName.slice(-2);
    return this;
  }
}
