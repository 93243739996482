import _ from 'lodash';
import experiment from 'experiment';
import {
  colorPaletteMigrationError,
  colorPaletteMigrationStart,
  colorPaletteMigrationFinish,
} from '@wix/bi-logger-editor-data/v2';
import { biLogger } from '#packages/util';
import type { FontId } from '@wix/document-services-types';

import type { EditorAPI } from '#packages/editorAPI';
import { templatesFedops as fedops } from './fedops';
import constants from '#packages/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { COLOR_ROLES } from '../colors/constants';
import type { ColorName, LinkedColors } from '../colors/types';
import { getColorNameByRole, unwrapColors } from '../colors/utils';
import { getColorsA11yIssues } from '../colors/a11y';
import {
  getMostUsedColors,
  generatePaletteToUpdateAccentColors,
  saveCustomColors,
  saveSiteMigratedFlag,
  setPageBg,
  getLineComponents,
  getComponentsColors,
} from './utils';
import { expandColorPalette } from './expandColorPalette';
import { runAutoWiring, runRemoveWiring } from './advancedWiring';
import { afterMigrationError, beforeMigration } from './revertMigrations';
import { sentryTags } from './constants';

const BI_ORIGIN = 'TemplatesFlow';

const migrateAccentColors = async (editorAPI: EditorAPI) => {
  const palette = editorAPI.theme.colors.getAll();

  const dynamicAccentColors = [
    getColorNameByRole(COLOR_ROLES.SECONDARY_2),
    getColorNameByRole(COLOR_ROLES.SECONDARY_3),
    getColorNameByRole(COLOR_ROLES.SECONDARY_4),
  ];
  const advancedColors: LinkedColors = {};

  const mostUsedColors = getMostUsedColors(editorAPI, {
    filterColorAppearingOnce: true,
  });

  const accentColors = mostUsedColors.slice(0, dynamicAccentColors.length);
  const userColors = mostUsedColors.slice(dynamicAccentColors.length);

  const paletteToUpdate = generatePaletteToUpdateAccentColors(
    accentColors,
    palette,
    dynamicAccentColors,
  );

  editorAPI.theme.colors.update({ ...paletteToUpdate, ...advancedColors });

  saveCustomColors(
    editorAPI,
    userColors.map((color) => palette[color]),
  );

  await editorAPI.waitForChangesAppliedAsync();
};

export const updateTextStyles = async (editorAPI: EditorAPI) => {
  const getFontColor = (font: string) =>
    unwrapColors(editorAPI.theme.textThemes.get(font as FontId).color);

  const palette = editorAPI.theme.colors.getAll();
  const visibleThemeColors = editorAPI.theme.colors.getVisibleThemeColorsKeys();

  const { h1, h2, h3, h4, h5, h6 } =
    constants.TEXT_CONTROLS.TEXT_THEMES_STYLES_MAP;

  const h1Color = getFontColor(h1.cssClass);
  const h2Color = getFontColor(h2.cssClass);

  const colorsToUpdate = {} as Record<ColorName, ColorName>;

  if (visibleThemeColors.includes(h2Color)) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.TITLE)] = h2Color;
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SUBTITLE)] = h2Color;
  }

  const h3h6Colors = [h3, h4, h5, h6].map(({ cssClass }) =>
    getFontColor(cssClass),
  );

  const differentHeadingColor = h3h6Colors.find(
    (color) => color !== h1Color && color !== h2Color,
  );

  if (visibleThemeColors.includes(differentHeadingColor)) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.SUBTITLE)] =
      differentHeadingColor;
  }

  const a11yIssues = getColorsA11yIssues({
    ...palette,
    ..._.mapValues(colorsToUpdate, (linkedTo) => palette[linkedTo]),
  });

  const colorToSet = _.mapValues(colorsToUpdate, (val, key) => {
    if (
      key === getColorNameByRole(COLOR_ROLES.PRIMARY_TEXT) ||
      key === getColorNameByRole(COLOR_ROLES.SUBTITLE)
    ) {
      return val;
    }
    return a11yIssues[key as ColorName]?.conflictColorRoles.length > 0
      ? getColorNameByRole(COLOR_ROLES.MAIN_2)
      : val;
  });
  editorAPI.theme.colors.update(colorToSet);

  await editorAPI.waitForChangesAppliedAsync();
};

export const updateLinesColors = async (editorAPI: EditorAPI) => {
  const lines = getLineComponents(editorAPI);
  const colorsToUpdate = {} as Record<ColorName, ColorName>;

  if (lines.length === 0) {
    colorsToUpdate[getColorNameByRole(COLOR_ROLES.LINE)] = getColorNameByRole(
      COLOR_ROLES.MAIN_2,
    );
  } else {
    const lineColors = getComponentsColors(editorAPI, lines);

    const frequencyMap = lineColors.reduce(
      (colorsToUsageMap: Record<ColorName, number>, colorName: ColorName) => {
        if (colorsToUsageMap[colorName]) {
          colorsToUsageMap[colorName] += 1;
        } else {
          colorsToUsageMap[colorName] = 1;
        }
        return colorsToUsageMap;
      },
      {} as Record<ColorName, number>,
    );

    lineColors.sort((colorValue, next) => {
      const v = frequencyMap[next] - frequencyMap[colorValue];
      if (v === 0) {
        return 1;
      }
      return v;
    });

    colorsToUpdate[getColorNameByRole(COLOR_ROLES.LINE)] =
      _.uniq(lineColors)[0];
  }
  editorAPI.theme.colors.update(colorsToUpdate);

  await editorAPI.waitForChangesAppliedAsync();
};

export const runTemplatesMigration = async (editorAPI: EditorAPI) => {
  biLogger.report(colorPaletteMigrationStart({ origin: BI_ORIGIN }));
  const {
    validateColors,
    originalColorPalette,
    originalFontsColors,
    originalWiring,
  } = beforeMigration(editorAPI);
  try {
    fedops.palleteMigration.start();

    await expandColorPalette(editorAPI);
    fedops.setAccents.start();
    await migrateAccentColors(editorAPI);
    fedops.setAccents.end();

    // autowiring is quite long, so we want to have new ui available sooner
    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();

    fedops.removeWiring.start();
    await runRemoveWiring(editorAPI);
    fedops.removeWiring.end();

    await updateTextStyles(editorAPI);
    await updateLinesColors(editorAPI);
    await runAutoWiring(editorAPI);

    await setPageBg(editorAPI);

    validateColors();
    fedops.palleteMigration.end();
    biLogger.report(colorPaletteMigrationFinish({ origin: BI_ORIGIN }));
  } catch (e: MaybeError) {
    if (experiment.isOpen('se_revertTemplatesMigrationResultIfError')) {
      await afterMigrationError(editorAPI, {
        originalFontsColors,
        originalColorPalette,
        originalWiring,
      });
    }
    biLogger.report(
      colorPaletteMigrationError({
        origin: BI_ORIGIN,
        errorMessage: e.message,
      }),
    );
    e.message = `[NEW COLOR PALETTE] SC migration failed:${e.message}`;
    ErrorReporter.captureException(e, {
      tags: { colorPaletteMigration: sentryTags.TEMPLATES },
    });
  }
};
