import type { CompData } from '@wix/document-services-types';
import { ColorMatch, type ColorName, type ColorPalette } from '../colors/types';
import { generateShadeColors } from '../utils';
import experiment from 'experiment';

import {
  COLOR_ROLES,
  COLOR_ROLE_TO_COLOR_NAME_MAP,
  THEME_COLOR_REGEX,
} from './constants';

const getColorValueByRole = (
  palette: Partial<ColorPalette>,
  role: COLOR_ROLES,
): string | null => palette[getColorNameByRole(role)] ?? null;

const getColorNameByRole = (role: COLOR_ROLES): ColorName | null =>
  COLOR_ROLE_TO_COLOR_NAME_MAP[role] ?? null;
/**
 * Map exisiting colors to new color palette
 * @param palette
 * @returns
 */

const getShadesFromBaseColors = ({ color_36, color_37 }: ColorPalette) => {
  const shades = generateShadeColors(color_36, color_37);

  return {
    [getColorNameByRole(COLOR_ROLES.SHADE_1)]: shades.shade1,
    [getColorNameByRole(COLOR_ROLES.SHADE_2)]: shades.shade2,
    [getColorNameByRole(COLOR_ROLES.SHADE_3)]: shades.shade3,
  };
};

function unwrapColors(maybeWrappedColors: string): ColorName;
function unwrapColors(maybeWrappedColors: string[]): ColorName[];
function unwrapColors(
  maybeWrappedColors: string | string[],
): ColorName | ColorName[] {
  const unwrap = (maybeWrappedColor: string) => {
    let color = maybeWrappedColor;
    if (typeof color === 'string' && color.includes('{')) {
      const regexp = /{(.*?)}/g;
      const firstMatch = [...color.matchAll(regexp)][0];
      color = firstMatch[1];
    }

    return color as ColorName;
  };

  return typeof maybeWrappedColors === 'string'
    ? unwrap(maybeWrappedColors)
    : maybeWrappedColors.map(unwrap);
}

const isTextCompData = (compData: CompData) => compData.type === 'StyledText';

/**
 * Extracts color value from the class attribute
 * @example
 * 'class="color_12"' => 'color_12'
 */
const extractColorFromClassAttr = (str: string) => {
  if (experiment.isOpen('se_colorPaletteUseNewRegEx')) {
    return str.match(THEME_COLOR_REGEX)[0] as ColorName;
  }

  return str.slice(7, -1) as ColorName;
};

const extractTextOrBackgroundColorFromClassAttr = (
  str: string,
): ColorMatch | null => {
  const regex = /class=(?:"|')?(backcolor_|color_)(\d+)(?:"|')?/;
  const match = str.match(regex);

  if (match) {
    const propertyType = match[1].startsWith('backcolor_')
      ? 'backcolor'
      : 'color';

    return new ColorMatch(match[2], propertyType);
  }

  return null;
};

/**
 * Extracts hex value from the class attribute
 * @example
 * 'style="color:#000000;"' => '#000000'
 */
const extractHexFromStyleAttr = (str: string) => str.match(/#\w{6}/)?.[0];

const OLD_COLOR_PALETTE_COLORS_NOT_INCLUDE_NEW = [13, 16, 17];
const isColorFromOldPalette = (colorNumber: number) => {
  return (
    OLD_COLOR_PALETTE_COLORS_NOT_INCLUDE_NEW.includes(colorNumber) ||
    (colorNumber >= 19 && colorNumber <= 35)
  );
};

const isThemeColor = (
  maybeColorName: ColorName | string,
): maybeColorName is ColorName => {
  return maybeColorName.includes('color');
};

export {
  getColorValueByRole,
  getColorNameByRole,
  getShadesFromBaseColors,
  unwrapColors,
  isTextCompData,
  extractColorFromClassAttr,
  isColorFromOldPalette,
  extractHexFromStyleAttr,
  extractTextOrBackgroundColorFromClassAttr,
  isThemeColor,
};
