import { createColorsApi } from './colors/api';
import type { ColorPalette } from './colors/types';
import type { FontStringsMap, TextThemeMap } from 'types/documentServices';
import type { ThemeScope } from './scope';

export function createThemeApi(scope: ThemeScope) {
  const { editorAPI } = scope;
  const colorsApi = createColorsApi(editorAPI);

  function updateStyle(
    styleId: AnyFixMe,
    styleValue: AnyFixMe,
    dontAddToUndoRedoStack: AnyFixMe,
  ) {
    if (editorAPI.updateHooks) {
      editorAPI.updateHooks.forEach((hook) => hook(styleId, styleValue));
    }

    editorAPI.dsActions.theme.styles.update(styleId, styleValue);

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.debouncedAdd('Update component style');
    }
  }

  function registerHook(hook: AnyFixMe) {
    editorAPI.updateHooks = editorAPI.updateHooks || [];
    editorAPI.updateHooks.push(hook);
  }

  const update = ({
    fonts,
    palette,
  }: {
    fonts?: Partial<TextThemeMap | FontStringsMap>;
    palette?: ColorPalette;
  }) => {
    if (palette) {
      colorsApi.update(palette);
    }

    if (fonts) {
      editorAPI.theme.textThemes.update(fonts as Partial<TextThemeMap>);
    }
  };

  return {
    colors: colorsApi,
    update,
    styles: {
      update: updateStyle,
      registerHook,
    },
  };
}
