import { hex } from 'wcag-contrast';
import type { RGBColorObject } from '../colors/types';

export const getContrastLevel = (color1: string, color2: string) => {
  try {
    return hex(color1, color2);
  } catch {
    // if `hex` function can fail if one of the arguments is not hex color
    // 21 is the contrast between black and white
    // in case something fails - a11y errors won't be shown
    return 21;
  }
};

export const isAccessible = (
  color1: string | null,
  color2: string | string[] | null,
  optimalLevel = 4.5,
) => {
  if (!color1 || !color2) return true;

  return Array.isArray(color2)
    ? color2.every((color) => getContrastLevel(color1, color) >= optimalLevel)
    : getContrastLevel(color1, color2) >= optimalLevel;
};

/**
 * Returns similarity in percentage between two colors (100 means the same colors)
 */
export const getColorDelta = (
  color1: RGBColorObject,
  color2: RGBColorObject,
) => {
  const redDelta = (255 - Math.abs(color1.red - color2.red)) / 2.55;
  const greenDelta = (255 - Math.abs(color1.green - color2.green)) / 2.55;
  const blueDelta = (255 - Math.abs(color1.blue - color2.blue)) / 2.55;

  return Math.round((redDelta + greenDelta + blueDelta) / 3);
};
