import { t } from '#packages/i18n';
import constants from '#packages/constants';
import { stringUtils, object as objectUtils, colors } from '#packages/util';
import {
  type ColorName,
  type ColorPalette,
  PALETTE_COLORS,
} from '#packages/theme';
import { events } from '#packages/coreBi';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  CeType,
  PaaSPageRenderedModel,
  PaaSRenderedModelNode,
} from '#packages/presetApi';
import type {
  CompRef,
  CompLayout,
  FontStringsMap,
  DocumentServicesObject,
} from 'types/documentServices';

const addSectionComponent = async (
  editorAPI: EditorAPI,
  compRef: CompRef,
  layout: Pick<CompLayout, 'y' | 'height'>,
  name: string,
) => {
  const sectionStructure = editorAPI.sections.getBlankSectionStructure({
    y: layout.y,
    height: layout.height,
    name,
  });
  return (await new Promise((resolve) => {
    editorAPI.components.add(compRef, sectionStructure, null, resolve);
  })) as CompRef;
};

export const parseNameFromNodeKey = (keyString: string): string => {
  const arr = keyString?.split('.');
  const nameArr = arr[1]?.split('_');
  nameArr?.pop();
  return nameArr?.join('_') || '';
};

const getTranslation = (ceType: string): string => {
  const key = `Section_Name_${ceType}`;
  const keyValue = t(key);
  return keyValue.includes(key) ? '' : keyValue;
};

const getSectionName = (sectionKey: string, ceType: string): string => {
  const translation = getTranslation(ceType);
  const sectionKeyAndCeTypeMismatch = !sectionKey.includes(ceType);
  if (sectionKeyAndCeTypeMismatch || !translation) {
    const ceTypeFromKey = parseNameFromNodeKey(sectionKey);
    return getTranslation(ceTypeFromKey);
  }
  return translation;
};

export const reverseStripsOrder = async (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
) => {
  for (const modelNode of pageModel.body.desktop.children) {
    editorAPI.components.arrangement.moveToFront(modelNode.compRef as CompRef);
    await editorAPI.waitForChangesAppliedAsync();
  }
};

const areAllUnderSections = (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
): boolean => {
  const totalStrips = pageModel.body.desktop.children.length;
  const allSections = editorAPI.components.getChildren(
    pageModel.pageRef as CompRef,
  );
  if (totalStrips !== allSections.length) return false;
  return allSections.every(
    (section) =>
      editorAPI.components.getType(section) === constants.COMP_TYPES.SECTION,
  );
};

const collectChildToParentMap = (
  presetSolution: PaaSRenderedModelNode,
  compIdChildToParentMap: { [child: string]: string } = {},
) => {
  presetSolution.children.forEach((childSolution) => {
    if (presetSolution.compRef?.id) {
      compIdChildToParentMap[childSolution.compRef.id] =
        presetSolution.compRef.id;
    }
    collectChildToParentMap(childSolution, compIdChildToParentMap);
  });
  return compIdChildToParentMap;
};

const wrapCompWithSection = async (
  editorAPI: EditorAPI,
  compRef: CompRef,
  pageRef: CompRef,
  sectionCeType: CeType,
  sectionName: string,
  layout: Pick<CompLayout, 'y' | 'height'>,
) => {
  const sectionRef = await addSectionComponent(
    editorAPI,
    pageRef,
    layout,
    sectionName,
  );
  editorAPI.dsActions.components.setContainer(compRef, sectionRef);
  await editorAPI.waitForChangesAppliedAsync();
  editorAPI.components.features.update(sectionRef, 'contentRole', {
    complexity: 'simple',
    type: 'ContainerRole',
    contentRole: sectionCeType,
  });
};

const sendSectionConversionErrorBi = (
  editorAPI: EditorAPI,
  mainComp: CompRef,
  parentRef: CompRef,
) => {
  const currentParentRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(mainComp);
  const currentParentType = editorAPI.components.getType(currentParentRef);
  const newParentType = editorAPI.components.getType(parentRef);
  editorAPI.bi.event(events.siteCreation.ERROR_PARENTING, {
    componentId: mainComp.id,
    componentType: editorAPI.components.getType(mainComp),
    currentParentId: currentParentRef?.id,
    currentParentType,
    newParentId: parentRef?.id,
    newParentType,
  });
};

const fixSectionsConversion = async (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
  sectionsCeTypes: CeType[],
) => {
  try {
    const pageRef = pageModel.pageRef as CompRef;
    const mainComps = editorAPI.components.getChildren(pageRef);
    const childToParentMap = collectChildToParentMap(pageModel.body.desktop);
    for (let i = 0; i < mainComps.length; i++) {
      const mainComp = mainComps[i];
      const compType = editorAPI.components.getType(mainComp);
      if (compType === constants.COMP_TYPES.SECTION) continue;
      const parentRef = editorAPI.components.get.byId(
        childToParentMap[mainComp.id],
      );
      sendSectionConversionErrorBi(editorAPI, mainComp, parentRef);
      await editorAPI.waitForChangesAppliedAsync();
      if (
        parentRef &&
        editorAPI.components.is.containable(mainComp, parentRef)
      ) {
        editorAPI.components.setContainer(mainComp, parentRef);
      } else {
        await wrapCompWithSection(
          editorAPI,
          mainComp,
          pageRef,
          sectionsCeTypes?.[i],
          '',
          editorAPI.components.layout.get_rect(mainComp),
        );
      }
    }
    await editorAPI.waitForChangesAppliedAsync();
  } catch (e: MaybeError) {
    console.log('fixSectionsConversion failed', e.message);
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationFixSectionsConversion: true,
      },
    });
  }
};

const verifyStripsPositionOnSections = (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
) => {
  try {
    const pageRef = pageModel.pageRef as CompRef;
    editorAPI.components.getChildren(pageRef).forEach((sectionRef) => {
      const [stripRef] = editorAPI.components.getChildren(sectionRef);
      const stripLayout = editorAPI.components.layout.get_position(stripRef);
      if (stripLayout && stripLayout.y !== 0) {
        editorAPI.dsActions.components.layout.update(stripRef, { y: 0 });
        ErrorReporter.captureException(
          new Error('Invalid strip position on section'),
          {
            tags: {
              siteCreationFlow: true,
              siteCreationVerifyStripsPosition: true,
            },
          },
        );
      }
    });
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        siteCreationFlow: true,
        siteCreationVerifyStripsPosition: true,
      },
    });
  }
};

export const fixCeTypes = (
  pageModel: PaaSPageRenderedModel,
  sectionsCeTypes: CeType[],
) => {
  let ceIndex = 0;
  return pageModel.body.desktop.children.map(({ key }) => {
    const ceType = sectionsCeTypes?.[ceIndex];
    if (key.includes(ceType)) {
      ++ceIndex;
      return ceType;
    }
    return parseNameFromNodeKey(key) as CeType;
  }) as CeType[];
};

const shouldRemoveModelNode = (modelNode: PaaSRenderedModelNode): boolean => {
  return !modelNode.children.every(({ children }) => children.length > 0);
};

const rearrangePageComponents = async (
  editorAPI: EditorAPI,
  pageRef: CompRef,
): Promise<void> => {
  const sortedPageChildrenWithLayout = editorAPI.components
    .getChildren(pageRef)
    .map((compRef: CompRef) => ({
      compRef,
      layout: editorAPI.components.layout.get_rect(compRef),
    }))
    .sort((a, b) => a.layout.y - b.layout.y);
  await sortedPageChildrenWithLayout.reduce(
    async (prevY, { compRef, layout }) => {
      const y = await prevY;
      editorAPI.components.layout.update(compRef, { y });
      return y + layout.height;
    },
    Promise.resolve(0),
  );
  await editorAPI.waitForChangesAppliedAsync();
};

export const convertToSections = async (
  editorAPI: EditorAPI,
  pageModel: PaaSPageRenderedModel,
  sectionsCeTypes: CeType[],
) => {
  await editorAPI.waitForChangesAppliedAsync();
  const fixedSectionsCeTypes = fixCeTypes(pageModel, sectionsCeTypes);
  for (const [index, modelNode] of pageModel.body.desktop.children.entries()) {
    if (shouldRemoveModelNode(modelNode)) {
      editorAPI.dsActions.components.remove(modelNode.compRef as CompRef);
      await editorAPI.waitForChangesAppliedAsync();
      await rearrangePageComponents(editorAPI, pageModel.pageRef as CompRef);
      continue;
    }
    const sectionCeType = fixedSectionsCeTypes?.[index];
    const sectionName = getSectionName(modelNode.key, sectionCeType);
    await wrapCompWithSection(
      editorAPI,
      modelNode.compRef as CompRef,
      pageModel.pageRef as CompRef,
      sectionCeType,
      sectionName,
      modelNode.compDef.layout,
    );
  }
  const isValid = areAllUnderSections(editorAPI, pageModel);
  if (!isValid) {
    await fixSectionsConversion(editorAPI, pageModel, fixedSectionsCeTypes);
  }
  verifyStripsPositionOnSections(editorAPI, pageModel);
};

export const getIsSlideshow = (editorAPI: EditorAPI, compRef: CompRef) => {
  const compListToCheck: string[] = [
    constants.COMP_TYPES.STRIP_SLIDE_SHOW,
    constants.COMP_TYPES.STRIP_SHOW_CASE,
  ];
  const compType = editorAPI.components.getType(compRef);
  return compListToCheck.includes(compType);
};

const paasColorThemeToInverseEntries = [
  ['color_11', 'color_15'],
  ['color_12', 'color_14'],
  ['color_16', 'color_35'],
  ['color_17', 'color_34'],
  ['color_18', 'color_33'],
  ['color_19', 'color_32'],
  ['color_20', 'color_31'],
  ['color_21', 'color_25'],
  ['color_22', 'color_24'],
  ['color_26', 'color_30'],
  ['color_27', 'color_29'],
];

/**
 * This function inverses the palette according to definition from https://jira.wixpress.com/browse/WEED-25417
 * After creating second line gradient colors, it assures that color_18 will be taken with the main color.
 * @param editorAPI
 */
export function inverseSiteThemeByPaasColorsEntries(
  editorAPI: EditorAPI,
): void {
  const COLOR_TO_CHANGE = 'color_19';
  const colorsTheme = editorAPI.theme.colors.getAll();
  const sitePalette = PALETTE_COLORS.reduce(
    (palette: ColorPalette, colorName: ColorName) => ({
      ...palette,
      [colorName]: colorsTheme[colorName],
    }),
    {},
  );
  const inversedPalette = objectUtils.invertObjectByEntries(
    sitePalette,
    paasColorThemeToInverseEntries,
  ) as unknown as ColorPalette;

  const secondLineMainColor = inversedPalette[COLOR_TO_CHANGE];
  const gradientColorsFromRoot = colors.createGradientColorsFromRoot(
    secondLineMainColor,
    inversedPalette as ColorPalette,
    ['color_16', 'color_17', 'color_18', 'color_19', 'color_20'],
  );
  if (gradientColorsFromRoot.color_20 === inversedPalette[COLOR_TO_CHANGE]) {
    gradientColorsFromRoot.color_19 = secondLineMainColor;
    gradientColorsFromRoot.color_18 = secondLineMainColor;
  } else if (
    gradientColorsFromRoot.color_19 === inversedPalette[COLOR_TO_CHANGE]
  ) {
    gradientColorsFromRoot.color_18 = secondLineMainColor;
  }
  const updatedPalette = Object.assign(inversedPalette, gradientColorsFromRoot);
  editorAPI.theme.colors.update(updatedPalette);
}

export const paasColorsToInverseEntries = [
  ['color_11', 'color_15'],
  ['color_12', 'color_14'],
  ['color_16', 'color_35'],
  ['color_18', 'color_32'],
  ['color_17', 'color_34'],
  ['color_33', 'color_32'],
  ['color_20', 'color_31'],
  ['color_21', 'color_25'],
  ['color_22', 'color_24'],
  ['color_26', 'color_30'],
  ['color_27', 'color_29'],
];

export const paasFontsToInvert: Partial<FontStringsMap> = {
  font_3: 'font_0',
  font_4: 'font_2',
  font_5: 'font_3',
  font_6: 'font_4',
  font_2: 'font_5',
  font_0: 'font_6',
};

export const reversePaasFontsToInvert = Object.entries(
  paasFontsToInvert,
).reduce((fonts, [key, val]) => ({ ...fonts, [val]: key }), {});

export function getFontThemeToUpdate(
  documentServices: DocumentServicesObject,
): Partial<FontStringsMap> {
  const fontTheme = documentServices.theme.fonts.getAll();

  return Object.fromEntries(
    Object.keys(paasFontsToInvert).map((fontName) => [
      fontName,
      fontTheme[fontName as keyof typeof paasFontsToInvert],
    ]),
  );
}

export const getFormContainer = (
  editorAPI: EditorAPI,
  mobilePageRef: CompRef,
) => {
  return editorAPI.components.get
    .byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.FORM_CONTAINER,
      mobilePageRef,
    )
    .find((formRef) => {
      const formWidget =
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(formRef);
      return (
        editorAPI.components.data.get(formWidget)?.controllerType === 'wixForms'
      );
    });
};

export const getSiteNameComp = (editorAPI: EditorAPI): CompRef => {
  const {
    businessCardData: { businessName },
    businessCardDataDefaults: { DEFAULT_BUSINESS_NAME },
  } = window.siteCreationController;
  const { htmlDecode } = stringUtils;
  const textToFilter = htmlDecode(businessName || DEFAULT_BUSINESS_NAME);

  return editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(
      editorAPI.siteSegments.getHeader(),
      true,
    )
    .find(
      (childRef) =>
        editorAPI.components.getType(childRef) === constants.COMP_TYPES.TEXT &&
        editorAPI.components.data
          .get(childRef)
          ?.text.match(new RegExp(textToFilter, 'i')),
    );
};
