import type { EditorAPI } from '#packages/editorAPI';
import { exisitingSitesFlowFedops as fedops } from './fedops';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { COLOR_ROLES } from '../colors/constants';
import type { LinkedColors } from '../colors/types';
import { getColorNameByRole } from '../colors/utils';
import { runRemoveWiring, runAutoWiring } from './advancedWiring';
import { updateTextStyles } from './templatesMigration';
import {
  colorPaletteMigrationError,
  colorPaletteMigrationStart,
  colorPaletteMigrationFinish,
} from '@wix/bi-logger-editor-data/v2';
import {
  getMostUsedColors,
  generatePaletteToUpdateAccentColors,
  saveCustomColors,
  saveSiteMigratedFlag,
} from './utils';
import { expandColorPalette } from './expandColorPalette';
import { EXISTING_SITE_MIGRATION_VERSION } from './version';
import { beforeMigration, afterMigrationError } from './revertMigrations';
import { biLogger } from '#packages/util';
import { sentryTags } from './constants';

const migrateAccentColors = async (editorAPI: EditorAPI, pageId?: string) => {
  const palette = editorAPI.theme.colors.getAll();

  const dynamicAccentColors = [
    getColorNameByRole(COLOR_ROLES.SECONDARY_2),
    getColorNameByRole(COLOR_ROLES.SECONDARY_3),
    getColorNameByRole(COLOR_ROLES.SECONDARY_4),
  ];
  const advancedColors: LinkedColors = {};

  const mostUsedColors = getMostUsedColors(editorAPI, {
    filterColorAppearingOnce: true,
    pageId,
  });

  const accentColors = mostUsedColors.slice(0, dynamicAccentColors.length);
  const userColors = mostUsedColors.slice(dynamicAccentColors.length);

  const paletteToUpdate = generatePaletteToUpdateAccentColors(
    accentColors,
    palette,
    dynamicAccentColors,
  );

  editorAPI.theme.colors.update({ ...paletteToUpdate, ...advancedColors });

  saveCustomColors(
    editorAPI,
    userColors.map((color) => palette[color]),
  );

  await editorAPI.waitForChangesAppliedAsync();
};

export const runExistingSiteMigrationForSiteWithNewColorsInComponents = async (
  editorAPI: EditorAPI,
) => {
  const BI_ORIGIN = 'EXISTING_SITES_NEW_COLOR_IN_COMPONENTS';

  biLogger.report(colorPaletteMigrationStart({ origin: BI_ORIGIN }));
  const {
    validateColors,
    originalColorPalette,
    originalFontsColors,
    originalWiring,
  } = beforeMigration(editorAPI);

  try {
    fedops.palleteMigration.start();

    fedops.setAccents.start();
    await migrateAccentColors(editorAPI);
    fedops.setAccents.end();

    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();

    await updateTextStyles(editorAPI);

    validateColors();

    fedops.palleteMigration.end();
    biLogger.report(colorPaletteMigrationFinish({ origin: BI_ORIGIN }));
  } catch (e: MaybeError) {
    await afterMigrationError(editorAPI, {
      originalFontsColors,
      originalColorPalette,
      originalWiring,
    });
    biLogger.report(
      colorPaletteMigrationError({
        origin: BI_ORIGIN,
        errorMessage: e.message,
      }),
    );
    e.message = `[NEW COLOR PALETTE][VERSION: ${EXISTING_SITE_MIGRATION_VERSION}] Existing sites migration for sites with existing new colors failed: ${e.message}`;
    console.log(e);

    ErrorReporter.captureException(e, {
      tags: { colorPaletteMigration: sentryTags.OLD_WITH_NEW_COMPONENT_COLORS },
    });
  }
};

export const runExistingSitesMigrationForNeverMigratedSites = async (
  editorAPI: EditorAPI,
) => {
  const BI_ORIGIN = 'EXISTING_SITES_NEVER_MIGRATED';
  biLogger.report(colorPaletteMigrationStart({ origin: BI_ORIGIN }));
  const {
    validateColors,
    originalColorPalette,
    originalFontsColors,
    originalWiring,
  } = beforeMigration(editorAPI);

  try {
    fedops.palleteMigration.start();

    await expandColorPalette(editorAPI);
    fedops.setAccents.start();
    await migrateAccentColors(editorAPI);
    fedops.setAccents.end();

    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();
    fedops.removeWiring.start();
    await runRemoveWiring(editorAPI);
    fedops.removeWiring.end();

    await updateTextStyles(editorAPI);

    await runAutoWiring(editorAPI);

    validateColors();

    fedops.palleteMigration.end();
    biLogger.report(colorPaletteMigrationFinish({ origin: BI_ORIGIN }));
  } catch (e: MaybeError) {
    await afterMigrationError(editorAPI, {
      originalFontsColors,
      originalColorPalette,
      originalWiring,
    });
    biLogger.report(
      colorPaletteMigrationError({
        origin: BI_ORIGIN,
        errorMessage: e.message,
        ts: Date.now(),
      }),
    );
    e.message = `[NEW COLOR PALETTE][VERSION: ${EXISTING_SITE_MIGRATION_VERSION}] Existing sites migration failed: ${e.message}`;

    console.log(e);
    ErrorReporter.captureException(e, {
      tags: { colorPaletteMigration: sentryTags.OLD },
    });
  }
};

export const runExistingHeavySitesMigration = async (editorAPI: EditorAPI) => {
  const BI_ORIGIN = 'EXISTING_HEAVY_SITE';

  biLogger.report(colorPaletteMigrationStart({ origin: BI_ORIGIN }));

  const {
    validateColors,
    originalColorPalette,
    originalFontsColors,
    originalWiring,
  } = beforeMigration(editorAPI);

  try {
    fedops.palleteMigration.start();

    await expandColorPalette(editorAPI);
    fedops.setAccents.start();
    const currentPageId = editorAPI.pages.getPrimaryPageId();
    await migrateAccentColors(editorAPI, currentPageId);
    fedops.setAccents.end();

    fedops.setFlag.start();
    saveSiteMigratedFlag(editorAPI);
    fedops.setFlag.end();

    await updateTextStyles(editorAPI);

    validateColors();

    fedops.palleteMigration.end();
    biLogger.report(colorPaletteMigrationFinish({ origin: BI_ORIGIN }));
  } catch (e: MaybeError) {
    await afterMigrationError(editorAPI, {
      originalFontsColors,
      originalColorPalette,
      originalWiring,
    });
    biLogger.report(
      colorPaletteMigrationError({
        origin: BI_ORIGIN,
        errorMessage: e.message,
        ts: Date.now(),
      }),
    );
    e.message = `[NEW COLOR PALETTE][VERSION: ${EXISTING_SITE_MIGRATION_VERSION}] Existing heavy sites migration failed: ${e.message}`;

    console.log(e);
    ErrorReporter.captureException(e, {
      tags: { colorPaletteMigration: sentryTags.HEAVY },
    });
  }
};
