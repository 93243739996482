import { COLOR_ROLES } from '../../../../colors';

export interface ButtonPathLists {
  [path: string]: COLOR_ROLES;
}

export const buttonPathLists: ButtonPathLists[] = [
  {
    'style.properties.bg': COLOR_ROLES.PRIMARY_BUTTON_FILL,
    'style.properties.brd': COLOR_ROLES.PRIMARY_BUTTON_BORDER,
    'style.properties.txt': COLOR_ROLES.PRIMARY_BUTTON_TEXT,
  },
  {
    'style.properties.bgh': COLOR_ROLES.PRIMARY_BUTTON_FILL_HOVER,
    'style.properties.brdh': COLOR_ROLES.PRIMARY_BUTTON_BORDER_HOVER,
    'style.properties.txth': COLOR_ROLES.PRIMARY_BUTTON_TEXT_HOVER,
  },
  {
    'style.properties.bgd': COLOR_ROLES.PRIMARY_BUTTON_FILL_DISABLED,
    'style.properties.brdd': COLOR_ROLES.PRIMARY_BUTTON_BORDER_DISABLED,
    'style.properties.txtd': COLOR_ROLES.PRIMARY_BUTTON_TEXT_DISABLED,
  },
  {
    'style.properties.bg': COLOR_ROLES.SECONDARY_BUTTON_FILL,
    'style.properties.brd': COLOR_ROLES.SECONDARY_BUTTON_BORDER,
    'style.properties.txt': COLOR_ROLES.SECONDARY_BUTTON_TEXT,
  },
  {
    'style.properties.bgh': COLOR_ROLES.SECONDARY_BUTTON_FILL_HOVER,
    'style.properties.brdh': COLOR_ROLES.SECONDARY_BUTTON_BORDER_HOVER,
    'style.properties.txth': COLOR_ROLES.SECONDARY_BUTTON_TEXT_HOVER,
  },
  {
    'style.properties.bgd': COLOR_ROLES.SECONDARY_BUTTON_FILL_DISABLED,
    'style.properties.brdd': COLOR_ROLES.SECONDARY_BUTTON_BORDER_DISABLED,
    'style.properties.txtd': COLOR_ROLES.SECONDARY_BUTTON_TEXT_DISABLED,
  },
];
