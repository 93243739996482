import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { FontId, TextThemeMap } from '@wix/document-services-types';
import { resultsValidator } from './validators';
import constants from '#packages/constants';
import type { ColorName, ColorPalette, LinkedColors } from '../colors/types';
import { unwrapColors } from '..';
import { EXISTING_SITE_MIGRATION_VERSION } from './version';

const HISTORY_LABEL = 'COLOR_MIGRATION';

export const revertSiteMigratedFlag = (editorAPI: EditorAPI) => {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.THEME.COLORS.IS_MIGRATED,
      false,
    ),
  );
};

export const revertLinkedColors = (
  editorAPI: EditorAPI,
  originalLinks: LinkedColors,
) => {
  // @ts-expect-error
  editorAPI.site.features.update('themeConfig', {
    colorMapping: originalLinks,
  });
};

const revertFonts = (
  editorAPI: EditorAPI,
  originalFontColors: Map<FontId, ColorName>,
) => {
  for (const [fontId, colorName] of originalFontColors) {
    const fullStyleObject = editorAPI.theme.textThemes.get(fontId);
    fullStyleObject.color = `{${colorName}}`;

    editorAPI.theme.textThemes.update({
      [fontId]: fullStyleObject,
    } as Partial<TextThemeMap>);
  }
};

export const revertMigrationChanges = async (
  editorAPI: EditorAPI,
  {
    originalColorPalette,
    originalFontsColors,
    originalWiring,
  }: {
    originalColorPalette: ColorPalette;
    originalFontsColors: Map<FontId, ColorName>;
    originalWiring: LinkedColors;
  },
) => {
  const { documentServices, history: historyAPI } = editorAPI;
  const undoLabelBeforeRevert = historyAPI.getUndoLastSnapshotLabel();

  await historyAPI.performUndoUntilLabel(HISTORY_LABEL, true);
  await documentServices.waitForChangesAppliedAsync();

  const undoLabelAfterRevert = historyAPI.getUndoLastSnapshotLabel();

  historyAPI.clear();

  revertFonts(editorAPI, originalFontsColors);
  revertLinkedColors(editorAPI, originalWiring);
  revertSiteMigratedFlag(editorAPI);
  editorAPI.dsActions.theme.colors.update(originalColorPalette);
  await documentServices.waitForChangesAppliedAsync();
  return {
    undoLabelBeforeRevert,
    undoLabelAfterRevert,
  };
};

const getOrignalFontStyles = (editorAPI: EditorAPI): Map<FontId, ColorName> => {
  const {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p: p1,
    div: p2,
    address: p3,
  } = constants.TEXT_CONTROLS.TEXT_THEMES_STYLES_MAP;

  return [h1, h2, h3, h4, h5, h6, p1, p2, p3].reduce((acc, item) => {
    acc.set(
      item.cssClass,
      unwrapColors(
        editorAPI.theme.textThemes.get(item.cssClass as FontId).color,
      ),
    );
    return acc;
  }, new Map());
};

export const beforeMigration = (editorAPI: EditorAPI) => {
  const { history: historyAPI } = editorAPI;
  const originalColorPalette = editorAPI.theme.colors.getAll();
  const initialHistoryLabel = historyAPI.getUndoLastSnapshotLabel();
  const originalFontsColors = getOrignalFontStyles(editorAPI);
  const validateColors = resultsValidator(editorAPI);
  const originalWiring = editorAPI.theme.colors.getAllLinkedColors();

  historyAPI.add(HISTORY_LABEL);

  return {
    originalColorPalette,
    initialHistoryLabel,
    validateColors,
    originalFontsColors,
    originalWiring,
  };
};

export const afterMigrationError = async (
  editorAPI: EditorAPI,
  options: {
    originalColorPalette: ColorPalette;
    originalFontsColors: Map<FontId, ColorName>;
    originalWiring: LinkedColors;
  },
) => {
  await revertMigrationChanges(editorAPI, options);
  setMigrationVersion(editorAPI);
};

export const setMigrationVersion = (editorAPI: EditorAPI) => {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      constants.USER_PREFS.THEME.COLORS.MIGRATION_VERSION,
      EXISTING_SITE_MIGRATION_VERSION,
    ),
  );
};

export const getMigrationVersion = (editorAPI: EditorAPI) => {
  return stateManagement.userPreferences.selectors.getSiteUserPreferences(
    constants.USER_PREFS.THEME.COLORS.MIGRATION_VERSION,
  )(editorAPI.store.getState());
};

export const skipMigrationBecauseOfVersion = (
  editorAPI: EditorAPI,
): boolean => {
  const currentVersion = getMigrationVersion(editorAPI);
  return currentVersion === EXISTING_SITE_MIGRATION_VERSION;
};
